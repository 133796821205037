.modal-ads-content{
    border-radius: 7%!important;
  }
  .modal-ads-content img{
    width:100%;
    object-fit: cover;
    object-position: center;
  }
  .modal-ads-container{
    max-width:1000px;
  }


  .modal-ads-container .btn-close{
    position: absolute;
    right:15px;
    top:15px;
  }