.modal-form-content{
    background: linear-gradient(180deg, #FDFEFF 0%, #EAF3FE 20.19%, #BDDFFB 38.75%, #7DC7F9 56.91%, #4CB0F8 67.96%, #39A5F7 75.07%, #1B8EF6 84.54%, #0574F6 100%);
    max-width:650px;
}

.modal-form-content form{
    height:600px;
    max-width:400px;
    margin:auto;
}

.modal-form-container.modal-dialog{
    max-width: 600px !important;
}


.modal-form-content form .form-control{
    height: 40px !important;
}

.modal-form-container{
    position:relative;
}

.modal-form-container .btn-close{
    position:absolute;
    z-index: 2;
    right:30px;
    top:30px;
}

.modal-form-footer{
    text-align:center;
        display:table-cell;
        vertical-align: center;
    margin-bottom:2rem;
}

.modal-form-footer span{
    font-size:2rem;
    font-weight:600;
}

.modal-form-footer img{

}



@media (max-width: 319px){
    .modal-form-content{
        padding:0px 1rem;
    }
    .modal-form-content .modal-form-logo{
        text-align:center;
    }

    .modal-form-content .modal-form-logo img{
        width:250px;
        margin:auto;
    }

    .modal-form-footer span{
        font-size:1.2rem;
    }
    
    .modal-form-footer img{
        width:50px;        
    }
}


@media (min-width: 320px) and (max-width:520px) { 
    .modal-form-content{
        padding:0px 1rem;
    }
    .modal-form-content .modal-form-logo{
        text-align:center;
    }

    .modal-form-content .modal-form-logo img{
        width:250px;
        margin:auto;
    }

    .modal-form-footer span{
        font-size:1.2rem;
    }
    
    .modal-form-footer img{
        width:50px;        
    }
}

.login-logo {
    text-align:center;
}
.login-logo img{
    max-width: 300px !important;;
}