

.lobby-wrapper{
    position:relative;

    margin-top:75px;
    padding:4rem 1rem 4rem;
    height: max-content;
}



.lobby-container{

    --game-w:200px;
    --grid-col-gap:10px;
    --skeleton-h:172px;
}

@media (max-width: 319px){
    .lobby-container{
        --game-w:135px;

        --grid-areas:"left" "right"14;
        --con-m:0.5rem 1rem;
        --fill-h:0x;
        --span-size: 16px;

        --skeleton-h:140px;
    }
}


@media (min-width: 320px) and (max-width:419px) { 
    .lobby-container{
        --game-w:135px;

        --grid-areas:"left" "right";
        --con-m:0.5rem 1rem;
        --fill-h:0x;
        
        --container-width: 0%;
        --container-gap:5%;
        
        --span-size: 17px;

        --skeleton-h:150px;
    }
}

@media (min-width: 420px) and (max-width:799px) { 
    .lobby-container{
        --game-w:140px;

        --grid-areas:"left" "right";
        --con-m:0.5rem 1rem;
        --fill-h:0x;
        --container-width: 5%;
        --container-gap:5%;
        --span-size: 17px;
        
        --grid-col-gap:2rem;
    }

}




@media (min-width: 800px) and (max-width:916px) { 
    .lobby-container{
        --game-w:135px;

        --grid-areas:"left" "right";
        --con-m:0.5rem 1rem;
        --fill-h:0x;
        
        --span-size: 17px;
        --grid-col-gap:2rem;
    }
}
@media (min-width: 917px) and (max-width:1049px) { 
    .lobby-container{
        --game-w:140px;
        --grid-col-gap:2rem;
        
        --span-size: 18px;

    }
}
@media (min-width: 1050px) and (max-width:1215px) { 
    .lobby-container{
        --game-w:150px;

        --grid-col-gap:2rem;
        --span-size: 18px;

    }
}

@media (min-width: 1216px) and (max-width:1218px) { 
    .lobby-container{
        --game-w:135px;
        --game-m:15px;
        
        --span-size: 19px;
    }
}
@media (min-width: 1219px) and (max-width:1399px) { 
    .lobby-container{
        --game-w:160px;
        --grid-col-gap:2rem;
        
        --span-size: 19px;
    }
}
@media (min-width: 1400px) and (max-width:2000px){
    .lobby-container{
        --game-w:200px;

        --grid-col-gap:10px;
        --span-size: 20px;
    }
}
@media (min-width:2000px) {
    .lobby-container{
        --container-width: 25%;
        --container-gap:5%;
        --game-w:160px;

        --span-size: 20px;
    }
}

@media (min-width: 1400px) and (max-width:1600px) {
    .lobby-container{
        --game-w:160px;

        --span-size: 20px;
        
        --grid-col-gap:3rem;

    }
}

@media (min-width: 1401px) {
    .lobby-wrapper{
        /* height:110vh; */
    }
}

@media (min-width: 1601px) {
    .lobby-container{
        --game-w:200px;

        --span-size: 20px;
        --grid-col-gap:10px;

    }
}

@media (min-width: 200px) and (max-width:991px) {
    .lobby-wrapper{
        margin-top:50px;
    }
}

@media (min-width: 992px) and (max-width:1600px) {
    .lobby-wrapper{
        margin-top:75px;
    }
}


.lobby-game-group{
    margin-bottom:1rem;
}
.lobby-game-group .lobby-game-content{
    display:grid;
    justify-items: center;
    align-items: start;
    grid-template-columns: repeat(auto-fill, minmax(var(--game-w), 1fr));
    grid-column-gap: var(--grid-col-gap);
    grid-row-gap:10px;
}

.skeleton-description{
    width:var(--game-w);
    height:var(--skeleton-h);

    margin-bottom:60px;
}



.game-content.show .icon-container{
    position:relative;
}

.game-content.show .icon-container img{
    width:var(--game-w);
}

.game-content.show .icon-container .icon-logo{
    position:absolute;
    z-index:2;
    left:0;
    top:10%;

}


.ind-jackpot{
    position: relative; 
    
  }
  .ind-jackpot span{
    position: absolute;
    bottom:0;
    z-index: 10;
    color:yellow;
    left: 20px;
  }
  
  .ind-jackpot img{
    z-index: -1;
  }
  
  .game-caption div{
    color:white;
  }