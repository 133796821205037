@import url(skeleton.css);
@import url(lobby-game.css);
@import url(background.css);
@import url(jackpot.css);
@import url(header.css);
@import url(footer.css);
@import url(game.css);

@import url(modal-form.css);


@import url(spinner.css);
@import url(hot.css);
@import url(login.css);
@import url(ads.css);
@import url(register.css);
@import url(custom.css);