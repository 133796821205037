.jackpot-container{
    text-align: center;

    --cont-w:unset;
    --fs:35px;
    --top:55%;
}
.jackpot{
    width:max-content;
    color:white;
    position:relative;

    margin:auto;


}
.jackpot .jackpot-content{
    position:absolute;
    top:var(--top);
    width:100%;


    
}

@media (max-width: 319px){
    .jackpot-container{
   
        --cont-w:95vw;
        --top:50%;
        
        --fs:30px;
    }
}


@media (min-width: 320px) and (max-width:520px) { 
    .jackpot-container{
   
        --cont-w:95vw;
        --top:50%;
        --fs:30px;
    }
}


.jackpot img{
    width:var(--cont-w);
}

.jackpot-content-text span{
    font-size:var(--fs);
}