:root {
    --loading-grey: #ededed;
  }
  
 
  
  .skeleton-card {
    /* width: 320px; */
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
    /* box-shadow: 0px 4px 6px rgba(0, 0, 0, .12); */
  }
  
  .skeleton-image {
    /* height: 300px; */
  }
  
  .skeleton-image img {
    display: block;
    width: 100%;
    height: inherit;
    object-fit: cover;
  }
  
  .skeleton-content {
    /* padding: 2rem 1.8rem; */
  }
  
  h4 {
    margin: 0 0 1rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  
  .skeleton-description {
    font-size: 1rem;
    line-height: 1.4rem;
    /* height:200px;
    width:200px; */
  }
  
  .skeleton-loading .skeleton-image,
  /* .skeleton-loading h4, */
  .skeleton-loading .skeleton-description {
    background-color: var(--loading-grey);
    background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, .5) 50%,
      rgba(255, 255, 255, 0) 60%
    ) var(--loading-grey);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
  }
  
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
  
  .skeleton-loading h4 {
    min-height: 1.6rem;
    border-radius: 4px;
    animation-delay: .05s;
  }
  
  .skeleton-loading .skeleton-description {
    min-height: 4rem;
    border-radius: 4px;
    animation-delay: .06s;
  }