.spinner-4 {
    width:50px;
    height:50px;
    border-radius:50%;
    padding:1px;
    background:conic-gradient(#0000 10%,#f03355) content-box;
    -webkit-mask:
      repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
      radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 calc(100% - 8px));
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
    animation:s4 1s infinite steps(10);
  }
  @keyframes s4 {to{transform: rotate(1turn)}}