.game-content.hide{
    display:none;
}

.game-content.show{
    display:block;
    position:relative;
    overflow: hidden;
}

.gr-skeleton.hide{
    display:none;
}


.game-content.show .icon-container{
    position:relative;
}

.game-content.show .icon-container .icon-logo{
    position:absolute;
    z-index:2;
    left:0;
    top:10%;
}
.game-content.show.options-open .icon-container img{
    filter:blur(4px);
}



.game-content.show .game-options .btn-close{
    position:absolute;
    top:-30px;
    right:0;
    color:white;
    background-color: white;
}


.game-content.show.options-open .game-options{
    display:block;
}
.game-content.show .game-options{
    display:none;
    position:absolute;
    bottom:20%;
    z-index: 5;
    text-align:center;
    background-color: rgba(0,0,0,0.0);
}
.game-content.show .game-options .btn{
    margin-bottom:4px;
}


.game-options-btn{
    max-width: 150px;
    margin:auto;
}
  
.game-options{
    width:100%;
    display: absolute;
    top:25%;
}

