.hot{
    position:absolute;
    top:0;
    right:0;
    background-color: #FF0404;
    color:white;
    width:35px;
    height:22.4px;
    border-radius: 10px 0px;
    font-size:10px;
    text-align: center;
    
    animation-name: blinkHot;
    animation-iteration-count:infinite;
    animation-duration: 0.5s;
    z-index: 1;
}


@keyframes blinkHot{
    from{
        background-color:red;
    }
    to{
        background-color: yellow;
        color:black;
    }
}