body{
    background-color: #060d28;
  }


  .social-container{
    position: absolute;
    bottom:0;
    padding:5px;
  }

  .cust-fb-share{
    font-size:11px;
    font-weight: 500;
    padding: 1px 8px;
  }
  .social-content{
    display: grid;
    grid-auto-flow: column;
  }
  
  
  .social-content button{
      display: grid;
    grid-auto-flow: column;
  
  }