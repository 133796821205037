
img.main-bg{
    --height:100%;
}


@media (max-width: 319px){
    img.main-bg{    

    }
}


@media (min-width: 1200px) and (max-width:1400px) { 
    img.main-bg{
        /* --height:120vh;   */
    }
}


img.main-bg{
    position:absolute;
    background-repeat: no-repeat;
    z-index: -1;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:100%;
    height:var(--height);
    object-fit: cover;
    object-position: center;
}