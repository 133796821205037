.navbar{
    --brand-img-w:72px;
}
.header-btn-mobile{
    position:absolute;
    right:85px;
}


@media (min-width: 320px) and (max-width:599px) { 
    .navbar{
        --brand-img-w:50px;
    }
}



@media (min-width: 600px) and (max-width:991px) { 
    .navbar{
        --brand-img-w:60px;
    }
}




@media (max-width: 992px){
    .navbar{
        --brand-img-w:50px;
    }
    .header-btn{
        position:absolute;
        right:85px;
    }
}


.navbar .navbar-brand img{
    width:var(--brand-img-w);
}